.offcanvas {
  transition: transform 0.8s ease-in-out !important;
}
.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  margin-right: auto;
  margin-left: auto;
  padding: 1rem;
}

.card-tools {
  float: right;
  margin-right: 0.625rem;
}

.not_ready_trans {
  background: #ff6464;
}
.list-icons {
  font-size: 25px;
}

.react-datetime-picker__wrapper,
.emptyFilter {
  height: 30px;
}

.d-flex,
.info-box,
.info-box .info-box-icon {
  display: -ms-flexbox !important;
  display: flex !important;
}

.justify-content-center,
.info-box .info-box-icon {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}
.align-items-center,
.info-box .info-box-icon {
  -ms-flex-align: center !important;
  align-items: center !important;
}

.info-box .info-box-icon > img {
  max-width: 100%;
}

.info-box .info-box-icon > img {
  max-width: 100%;
}

.info-box .info-box-icon {
  border-radius: 0.25rem;
  display: block;
  font-size: 1.875rem;
  text-align: center;
  width: 70px;
}

.info-box .info-box-icon > img {
  max-width: 100%;
}

.info-box .info-box-content {
  -ms-flex: 1;
  flex: 1;
  padding: 5px 10px;
}

.info-box .progress-description,
.info-box .info-box-text {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.info-box {
  margin-top: 1rem;
  min-height: 150px;
}

.info-box {
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 0.25rem;
  background: #ffffff;
  min-height: 80px;
  padding: 0.5rem;
  position: relative;
}

.devices-list {
  margin-bottom: 0.5rem;
}

/* not Found */

#notfound {
  position: relative;
  height: 100vh;
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notfound {
  max-width: 520px;
  width: 100%;
  line-height: 1.4;
  text-align: center;
}

.notfound .notfound-404 {
  position: relative;
  height: 240px;
}

.notfound .notfound-404 h1 {
  font-family: 'Montserrat', sans-serif;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 252px;
  font-weight: 900;
  margin: 0px;
  color: #262626;
  text-transform: uppercase;
  letter-spacing: -40px;
  margin-left: -20px;
}

.notfound .notfound-404 h1 > span {
  text-shadow: -8px 0px 0px #fff;
}

.notfound .notfound-404 h3 {
  font-family: 'Cabin', sans-serif;
  position: relative;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: #262626;
  margin: 0px;
  letter-spacing: 3px;
  padding-left: 6px;
}

.notfound h2 {
  font-family: 'Cabin', sans-serif;
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  color: #000;
  margin-top: 0px;
  margin-bottom: 25px;
}

@media only screen and (max-width: 767px) {
  .notfound .notfound-404 {
    height: 200px;
  }
  .notfound .notfound-404 h1 {
    font-size: 200px;
  }
}

@media only screen and (max-width: 480px) {
  .notfound .notfound-404 {
    height: 162px;
  }
  .notfound .notfound-404 h1 {
    font-size: 162px;
    height: 150px;
    line-height: 162px;
  }
  .notfound h2 {
    font-size: 16px;
  }
}
