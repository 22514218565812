.brand-name {
  text-decoration: none;
  color: black;
  font-size: 1.3rem;
  margin-left: 3rem;
}

.Logo {
  height: 30px;
  margin-left: 5px;
}
